import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfigInitService, MaterialModule, TemplateNptModule, getPropertyFromConfig } from '@npt/npt-template';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { DashboardComponent } from './components/comp-dashboard/dashboard.component';
import { MenuItemService } from './npt-template-menu/menu-item.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  const packageObj = require('../../package.json');
  const version = packageObj.version;
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?version=' + version);
}


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TemplateNptModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    // npt template
    { provide: 'menuService', useClass: MenuItemService },
    { provide: 'header', useValue: environment.header },
    { provide: 'footer', useValue: environment.footer },
    { provide: 'dashboard', useValue: '/dashboard'},
    { provide: 'env', useValue: environment.security },
    // npt obu
    { provide: 'timeOutTest', useValue: 'timeOutTest' },
    {
      provide: 'timeOutTestData',
      useFactory: getPropertyFromConfig, multi: false, deps: ['timeOutTest', ConfigInitService]
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
